import type {
  Event as SentryEvent,
  EventHint,
  Integration,
} from "@sentry/types";

import { pipe } from "ramda";

import { deepToString, mergeDeep } from "@solvari/utils";

import type {
  SentryProject,
  SentryRootProject,
} from "@/lib/integrations/sentry/types";

import { getClientSideArgusProjection } from "@/lib/argus/client/thickClient";
import { getArgusEvents } from "@/lib/argus/client/thinClient";
import {
  getEventProject,
  setEventProject,
} from "@/lib/integrations/sentry/service/project";

const addDataLayerData: SentryProject["addData"] = () => ({
  contexts: { dataLayer: { events: window.dataLayer ?? [] } },
});

const addArgusData: SentryProject["addData"] = () => ({
  contexts: {
    argusEvents: { events: getArgusEvents() },
    metadata: { ...getClientSideArgusProjection() },
  },
});

const applyDataToEvent =
  (hint: EventHint, addData: SentryProject["addData"]) =>
  (event: Readonly<SentryEvent>): SentryEvent => {
    if (!addData) {
      return event;
    }

    return mergeDeep(event, deepToString(addData(event, hint)));
  };

const solvariFilterIntegration = (): Integration => ({
  name: "SolvariFilter",
  processEvent(event) {
    if (
      event.exception?.values?.[0]?.value === "undefined" ||
      event.contexts?.browser?.name === "HeadlessChrome"
    ) {
      return null;
    }
    const frames = event.exception?.values?.[0]?.stacktrace?.frames;
    if (frames?.length === 1 && frames[0]?.filename === "<anonymous>") {
      return null;
    }
    return event;
  },
});

const solvariContextIntegration = (
  rootProject: SentryRootProject,
): Integration => ({
  name: "SolvariContext",
  processEvent(event, hint) {
    if (event.exception?.values?.[0]?.value === "undefined") {
      return null;
    }

    const project = getEventProject(event);
    return pipe(
      (event: SentryEvent) => setEventProject(event, project),
      applyDataToEvent(hint, addDataLayerData),
      applyDataToEvent(hint, addArgusData),
      applyDataToEvent(hint, rootProject.addData),
      applyDataToEvent(hint, project?.addData),
    )(event);
  },
});

export { solvariContextIntegration, solvariFilterIntegration };
